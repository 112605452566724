import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Input, Button, Row, Col } from 'antd';
import Content from '../content';
import styled, {CSSProperties} from 'styled-components';

import ContactSvg from './static/contact.svg';
import { State as RootState } from '../../types';
import { connect } from 'react-redux';
import { contactUrl } from '@/routes';
import { iState as iStateProfile } from '../../state/models/profile';

const Wrapper = styled.div`
	h2 {
		text-align: center;
		font-size: 2.5rem;
		font-weight: 400;
	}

	.ant-btn {
		height: 60px;
		font-size: 1.3rem;
		padding: 0 25px;
		border-radius: 0;
		margin-top: 30px;
		font-weight: 800;
	}

	img {
		width: 70%;
		margin: 0 auto;
	}

	.age {
		position: absolute;
		top: -1000px;
	}
`;

interface Props {
	form: any;
	profile: iStateProfile;
	showtitle?: boolean;
	style?: CSSProperties;
}

interface State {
	botDetected: boolean;
}

const encode = (data: any) => {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
};

class ContactForm extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	state = {
		botDetected: false,
	};

	handleSubmit = (e: any) => {
		e.preventDefault();

		const self = this;
		const { form } = this.props;

		self.props.form.validateFields((err: string, values: string[]) => {

			if (!err) {
				if (!self.state.botDetected) {
					fetch(contactUrl, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
						},
						body: encode({
							'form-name': 'contact', ...values,
						}),
					}).then(() => {
						message.success('Message was sent', 5);
					}).catch(error => alert(error));
				}

				form.resetFields();
			}
		});
	};

	render() {
		const { form, profile, ...rest } = this.props;
		const { getFieldDecorator } = form;
		return (
			<Wrapper {...rest}>
				<Content padding={'0 24px'}>
					<Row gutter={48}>
						<Col lg={12} xs={24}>
							<Form name={'contact'} onSubmit={this.handleSubmit} data-netlify="true">
								<input className={'age'} type="text" name={'age'} onChange={() => {
									this.setState({ botDetected: true });
								}}/>
								<Form.Item>
									{getFieldDecorator('name', {
										rules: [{
											required: true,
											message : 'Please input your name',
										}],
									})(
										<Input name="name" size={'large'} placeholder="Your Name"/>,
									)}
								</Form.Item>
								<Form.Item>
									{getFieldDecorator('email', {
										initialValue: profile.data.email || '',
										rules       : [{
											required: true,
											message : 'Please input your email',
										}],
									})(
										<Input name="email" disabled={profile.data.email != ''}
											 size={'large'}
											 placeholder="Your Email"/>,
									)}
								</Form.Item>
								<Form.Item>
									{getFieldDecorator('message', {
										rules: [{
											required: true,
											message : 'Please input your message',
										}],
									})(
										<Input.TextArea name={'message'} rows={8}/>,
									)}
								</Form.Item>
								<Form.Item>
									<Button type="primary" block={true} htmlType="submit">
										Send message
									</Button>
								</Form.Item>
							</Form>
						</Col>

						<Col lg={12} xs={0} style={{ textAlign: 'center' }}>
							<img src={ContactSvg} alt="Contact us"/>
						</Col>
					</Row>
				</Content>
			</Wrapper>
		);
	}
}

const WrappedContactForm = Form.create({ name: 'dnsadblock-contact' })(ContactForm);

const mapStateToProps = (state: RootState) => {
	return {
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(WrappedContactForm);

