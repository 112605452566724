import React from 'react';

import Layout from '../components/layout/public';
import Hero from '../components/public/page-hero';
import Faq from '../components/public/faq';
import Contact from '../components/public/contact';
import SEO from '../components/seo';

const SupportPage = () => (
	<Layout>
		<SEO
			title="DnsAdBlock support and contact page"
			description={`Get support regarding our services and our ad blocking technology. Keep yourself and your family safe while browsing the internet.`}
		/>

		<Hero title={'Support'} style={{
			marginBottom: 50,
		}}/>

		<Contact style={{
			marginBottom: 150,
		}}/>

		<Faq style={{
			marginBottom: 50,
		}}/>
	</Layout>
);


export default SupportPage;
